let keys: string[] = [];

export const getUniqueKey = (): string => {
  let newKey = '__' + String(String(Math.random() * 9999) + String(new Date().getTime()));
  newKey = newKey.split('.').join('');
  if (keys.indexOf(newKey) === -1) {
    keys.push(newKey);
    return newKey;
  }
  return getUniqueKey();
};

export const isAnUniqueKey = (key: string): boolean => {
  return keys.indexOf(key) > -1 ? true : false;
}